
                @import '@style/mixins';
                @import '@skillbox/front-box/dist/Functional/UiVariables/style';
              
.start-screen-v3 {
  &.block {
    padding-top: 0;
  }
}

.start-screen-v3__block {
  position: relative;
  margin-right: 4px;
  margin-left: 4px;
  padding-top: 48px;
  padding-bottom: 20px;
  border-radius: 20px;
  color: var(--admin-color);
  background-color: var(--start-screen-bg);

  @include media(md) {
    padding-bottom: 32px;
  }

  @include media(lg) {
    padding-bottom: 48px;
    border-radius: 32px;
  }

  @include media(xl) {
    width: calc(100% - 40px);
    max-width: 1400px;
    min-height: 720px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 40px;
    padding-bottom: 64px;
    overflow: hidden;
  }
}

.start-screen-v3__wrapper {
  display: grid;
  grid-template-rows: auto auto 1fr auto;
  grid-template-columns: 1fr;

  @include media(sm-max) {
    padding-right: 16px;
    padding-left: 16px;
  }

  @include media(xl) {
    grid-template-columns: 299px 297px 1fr;
  }

  &--example {
    @include media(sm-max) {
      position: relative;
    }
  }
}

.start-screen-v3__title {
  grid-row: 1/2;
  grid-column: 1/2;
  margin-bottom: 16px;

  @include media(sm-max) {
    text-align: center;
  }

  @include media(lg) {
    grid-row: 2/3;
    margin-bottom: 40px;
  }

  @include media(xl) {
    grid-column: 1/3;
    margin-bottom: 72px;
  }
}

.start-screen-v3__subtitle {
  display: block;
  grid-row: 2/3;
  grid-column: 1/2;
  margin-bottom: 24px;

  @include media(sm-max) {
    text-align: center;
  }

  @include media(lg) {
    grid-row: 1/2;
  }

  @include media(xl) {
    grid-column: 1/3;
    margin-top: 52px;
  }
}

.start-screen-v3__pic {
  grid-row: 3/4;
  grid-column: 1/2;
  width: 240px;
  height: 240px;
  margin: 0 auto 24px;

  @include media(lg) {
    width: 468px;
    height: 468px;
    margin-bottom: 40px;
  }

  @include media(xl) {
    grid-row: 1/4;
    grid-column: 3/-1;
    align-self: flex-start;
    justify-self: flex-end;
    margin-right: 0;
    margin-left: 0;
  }

  &--example {
    position: relative;
    width: calc(100% + 32px);
    height: unset;

    @include media(sm-max) {
      margin: 0 -16px -64px;
      padding-top: calc(100% / 4 * 3.96);
    }

    @include media(md) {
      justify-self: flex-end;
      width: 548px;
      height: 493px;
      margin-right: -32px;
      margin-bottom: -104px;
    }

    @include media(lg) {
      width: 684px;
      height: 616px;
      margin-right: -44px;
      margin-bottom: -108px;
    }

    @include media(lg-max) {
      margin-left: auto;
    }

    @include media(xl) {
      grid-row: 1/-1;
      margin-right: -116px;
      margin-bottom: 0;
    }

    img {
      @include media(sm-max) {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
}

.start-screen-v3__author {
  display: inline-block;
  grid-row: 3/4;
  grid-column: -2/-1;
  align-self: flex-end;
  justify-self: flex-end;
  margin-bottom: 16px;
  padding: 8px 12px;
  border-radius: 20px;
  color: var(--accent-always-white-color);
  background: rgba(var(--accent-always-black-color-rgb), .4);
  backdrop-filter: blur(10px);

  @include media(md) {
    margin-bottom: 20px;
  }

  @include media(xl) {
    position: absolute;
    right: 20px;
    align-self: flex-start;
    margin-top: -20px;
    margin-bottom: 0;
  }
}

.start-screen-v3__utp {
  @include ulres;

  position: relative;
  display: flex;
  grid-row: -2/-1;
  grid-column: 1/-1;
  gap: 4px;

  @include media(sm-max) {
    flex-wrap: wrap;
  }

  @include media(lg) {
    gap: 8px;
  }
}

.start-screen-v3__item {
  flex-basis: 100%;
  padding: 20px;
  border-radius: 20px;
  color: var(--text-main-color);
  background-color: var(--bg-with-shadow-color);

  @include media(lg) {
    border-radius: 24px;
  }

  &--sale {
    @include media(lg-max) {
      display: none;
    }

    @include media(xl) {
      color: var(--admin-color);
      background-color: var(--tooltip-bg-color);
    }
  }
}

.start-screen-v3__utp-title {
  display: block;
  margin-bottom: 8px;
}

// шрифт захардкожен по просьбе Ани Фендик
.start-screen-v3__desc {
  margin-bottom: 0;
  color: var(--text-secondary-color);
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  &--timer {
    color: var(--admin-color);
  }
}

// стики-плашка
.start-screen-v3__bar {
  @include media(lg-max) {
    @include z-index(fixed, elevated);
    @include ui-shadow-8pt;
    bottom: 0;

    left: 0;
    width: 100%;
    padding: 8px 20px 20px;
    border-radius: 20px 20px 0 0;
    color: var(--text-main-color);
    background-color: var(--bg-with-shadow-color);
    transition: $transition;
    will-change: transform;
  }

  @include media(xl) {
    grid-row: 3/4;
    grid-column: 1/2;
    align-self: flex-start;
    margin-bottom: 40px;
  }

  &--hidden {
    @include media(lg-max) {
      transform: translateY(200%);
    }
  }
}

.start-screen-v3__timer {
  font-feature-settings: 'tnum';
}

.start-screen-v3__info {
  margin-bottom: 8px;
  text-align: center;

  @include media(xl) {
    display: none;
  }
}

.start-screen-v3__sale-percent {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 20px;
  color: var(--admin-color);
  background-color: var(--tooltip-bg-color);
}
